<template>
  <nav v-if="breadcrumbs && breadcrumbs.length" class="breadcrumbs">
    <ul class="breadcrumbs__list" itemscope itemtype="https://schema.org/BreadcrumbList">
      <li
        v-for="(item, index) in breadcrumbs"
        :key="index"
        class="breadcrumbs__item font"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
      >
        <nuxt-link
          v-if="(index + 1) !== breadcrumbs.length"
          :to="item.url"
          class="breadcrumbs__link font_capitalize"
          itemprop="item"
        >
          <span itemprop="name">{{ item.name }}</span>
          <meta itemprop="position" :content="index" />
        </nuxt-link>
        <div v-else class="breadcrumbs__link breadcrumbs__link--current font_capitalize">
          <span itemprop="name">{{ item.name }}</span>
          <meta itemprop="position" :content="index" />
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>
